import {
  FieldPosition, PendingRemoteSigningSessionFieldCustom,
  PendingRemoteSigningSessionPage,
  SigningSessionFieldType,
  SigningSessionSubType, TimestampPosition
} from '@property-folders/contract';
import { Dimension } from '@property-folders/common/signing/draw-compound-signature';
import { useState } from 'react';

export function asPercentage(originalValue: number, containerValue: number) {
  if (containerValue === 0) {
    return originalValue;
  }

  return `${100 * (originalValue / containerValue)}%`;
}

export function loadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new window.Image();
    image.onload = () => resolve(image);
    image.onerror = err => {
      image.remove();
      reject(err);
    };
    image.src = src;
  });
}

// gotta keep these here because we don't wanna reference @property-folders/common (/util/formatting)
export function isValidTimeZone(timeZone: string) {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: timeZone });
    return true;
  }
  catch (ex) {
    return false;
  }
}

export function formatTimestamp(timestamp: number, timeZone: string, includeTime = true): string {
  try {
    // DD-MMM-yyyy HH:mm (TZ)
    // 12-Jan-2023 09:45 (ACST)
    const formatter = new Intl.DateTimeFormat('en-AU', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...includeTime && {
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
        timeZoneName: 'short'
      },
      timeZone: timeZone && isValidTimeZone(timeZone)
        ? timeZone
        : 'Australia/Adelaide'
    });
    const parts = formatter.formatToParts(new Date(timestamp));
    return parts.map(({ type, value }, index, array) => {
      const prev = array[index - 1]?.type;
      switch (type) {
        case 'literal':
          if (prev === 'day' || prev === 'month') {
            return '-';
          }
          if (prev === 'year') {
            return ' ';
          }
          return value;
        case 'timeZoneName':
          return `(${value})`;
        default:
          return value;
      }
    }).join('');
  } catch {
    return new Date(timestamp).toISOString();
  }
}

export interface FieldProps {
  id: string;
  type: SigningSessionFieldType;
  subtype: SigningSessionSubType;
  name: string;
  timestamp: number;
  timestampPosition?: TimestampPosition
  timeZone: string;
  page: PendingRemoteSigningSessionPage;
  location: FieldPosition;
  minCompositeSignatureDimensions?: Dimension;
  fillField?: (text?: string) => void;
  clearField?: () => void;
  text?: string;
  image?: string;
  pixelsPerInch: number;
  custom?: PendingRemoteSigningSessionFieldCustom;
  observer?: IntersectionObserver;
}

export function useFieldVisibilityAid(condition: boolean) {
  const [touched, setTouched] = useState(false);
  const showAid = !touched && condition;
  return {
    className: showAid
      ? 'field-visibility-aid'
      : '',
    showAid,
    setTouched
  };
}
